import { createDetailPage } from '@amzn/monera-app/lib/pages/dp/[asin]';

import { componentsMap } from '../../components-map';

const DetailPage = createDetailPage({
  componentsMap,
  spOverrides: {
    pageFooterStyle: 'brief' // page footer without recently viewed.
  }
});

export default DetailPage;
